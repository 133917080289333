import React from 'react'
import CommonBanner from '../component/Common/Banner'
import History from '../component/About/History'
import Testimonials from '../component/Common/Testimonials'
import TeamMembers from '../component/About/Team'

const About = () => {
    return (
        <>
            <CommonBanner heading="About" menu1="Home" menu2="About"/>
            <History/>
            {/* <TeamMembers/> */}
        </>
    )
}

export default About
