import React from 'react'
import {FiChevronDown} from "react-icons/fi"

// import {Link} from 'react-router-dom';
import { Link } from 'react-scroll'


const NavItem = props => {
    return (
        <>
            <li className="nav-item">
                <Link to={props.item.href} offset={-150} smooth={true} spy={true} activeClass="active" className="nav-link" style={{cursor: 'pointer'}} onClick={props.click}>
                    {props.item.name}
                    {props.item.has_children && (
                        <i><FiChevronDown/></i>
                    )}
                </Link>
                {props.item.has_children && (
                    <ul className="dropdown-menu">
                        {props.item.children.map((item, index) => (
                            <NavItem item={item} key={index}/>
                        ))}
                    </ul>
                )}
            </li>
        </>
    )
}

export default NavItem
