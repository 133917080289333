import React from 'react';
import {BrowserRouter as Router, Switch, Route, BrowserRouter} from 'react-router-dom';

// All Page Component
import Home from '../src/page/Home'
import About from '../src/page/About'
import Projects from '../src/page/Projects'
import Contact from '../src/page/Contact'
import Error from '../src/page/Error'
import Workwithus from '../src/page/workwithus'

// Scrolling Helpers
// import ScrollToTop from "./helpers/ScrollToTop";
import SmoothScroll from "./helpers/ScrollToTop";

// Layout Component
import Header from '../src/layout/Header'
import Footer from '../src/layout/Footer'
import Cta from '../src/layout/Cta'
import CopyRight from '../src/layout/CopyRight'



const App = () => {
    return (
        <>
            <BrowserRouter>
                <Router>
                    {/* <ScrollToTop> */}
                    <SmoothScroll>
                        <Header/>
                        <Switch>
                            <Route path='/' exact component={Home}/>
                            <Route path='/about' exact component={About}/>
                            <Route path='/workwithus' exact component={Workwithus}/>
                            <Route path='/projects' exact component={Projects}/>
                            <Route path='/contact' exact component={Contact}/>
                            <Route exact component={Error}/>
                        </Switch>
                        {/* <Cta/> */}
                        <Footer/>
                        <CopyRight/>
                    </SmoothScroll>
                    {/* </ScrollToTop> */}
                </Router>
            </BrowserRouter>
        </>
    );
}

export default App;
