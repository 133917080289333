import React from 'react'
// import About Img
import img1 from '../../assets/img/about-page.png'
// import Icon
import {FiCheck} from 'react-icons/fi';

const History = () => {
    return (
        <>
            {/* About Top Area Start */}
            <section id="about-top" className="py100 bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about-img">
                                <h2>History begins in 2021 with the foundation</h2>
                                <p>
                                    Yabok Techonology is a company dedicated to the development 
                                    of technological projects for people and companies at a Global
                                    level, we aim to grow your business and take your idea to the
                                    next level, focused on the innovation and growth of each of our
                                    clients, ensuring maximum user experience. At Yabok Technology
                                    we believe in the progress of society and the human condition 
                                    with the fusion of intelligence, reason and science. 
                                    That is why we prove to be your trusted partner on the digital 
                                    integration path.
                                </p>
                                <p>
                                    We believe in the progress of humanity by merging intelligence,
                                    technology and science. That is why we prove to be your partner
                                    trusted on the digital integration path towards
                                    transformation of society.
                                </p>
                                <ul>
                                    <li><i><FiCheck/></i>Data Science</li>
                                    <li>
                                        <i><FiCheck/></i>Machine Learning</li>
                                    <li><i><FiCheck/></i>Artificial Intelligence</li>
                                    <li>
                                        <i><FiCheck/></i>Internet of things
                                    </li>
                                    <li><i><FiCheck/></i>Fintech</li>
                                    <li><i><FiCheck/></i>Full Stack Development</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about-top-img animation-img-two">
                                <img alt="" src={img1} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About Top Area Start */}
        </>
    )
}

export default History
