import React from 'react'
import CommonBanner from '../component/Common/Banner'
import GetStarted from '../component/Common/GetStarted/index'
import ContactDetails from '../component/Contact'

const Workwithus = () => {
    return (
        <>
            <CommonBanner heading="Join us" menu1="Home" menu2="Join us"/>
            <GetStarted/>
            <ContactDetails/>
        </>
    )
}

export default Workwithus
