import Icon1 from '../../../assets/img/service/1.png'
import Icon2 from '../../../assets/img/service/2.png'
import Icon3 from '../../../assets/img/service/3.png'
import Icon4 from '../../../assets/img/service/4.png'
import Icon5 from '../../../assets/img/service/5.png'
import Icon6 from '../../../assets/img/service/6.png'

export const OurServiceData = [
{
 img:Icon1,
 heading:"Data Science",
 paragraph:"We excel ourselves going one step further because we want to respond, exceed your expectation and surprise you.",
 button:"Read More ..."
},
{
 img:Icon2,
 heading:"Machine Learning",
 paragraph:"Machine Learning is the technology that can use algorithms to capitalize on your data to generate economic impact.",
 button:"Read More ..."
},
{
 img:Icon3,
 heading:"Artificial Intelligence",
 paragraph:"Take advantage of the power of AI in every business decision to achieve significant impacts that respond quickly to changes.",
 button:"Read More ..."
},
{
 img:Icon4,
 heading:"IoT",
 paragraph:"Internet of Things or simply IoT is a term that is currently being used with great popularity. Nowadays there are few developers who don't launch devices oriented to this.",
 button:"Read More ..."
},
{
 img:Icon5,
 heading:"Full Stack Development",
 paragraph:"Nowaday is highly demanded developers who can influence different areas of development: with us you will find a high-quality full stack team.",
 button:"Read More ..."
},
/*{
 img:Icon6,
 heading:"FinTech",
 paragraph:"We provide banking services for your projects, allowing you to send and receive money from any place of the world, digital wallets, and full integrated accounts.",
 button:"Read More ..."
}*/
]
