import img1 from '../../assets/img/project-grid/1.jpg'
import img2 from '../../assets/img/project-grid/2.jpg'
import img3 from '../../assets/img/project-grid/3.jpg'

export const ProjectData = [
    {
        img: img1,
        title: "Artificial Intelligence"
    },
    {
        img: img2,
        title: "Web Design"
    },
    {
        img: img3,
        title: "App Design"
    },
    {
        img: img2,
        title: "Web Application"
    },
    {
        img: img3,
        title: "App Development"
    },
    {
        img: img1,
        title: " SEO "
    },
    {
        img: img1,
        title: "App Design"
    },
    {
        img: img2,
        title: "Web Design"
    },

    {
        img: img3,
        title: "Web Application"
    }
]