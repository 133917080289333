import React from 'react'

import SectionHeading from '../SectionHeading/SectionHeading'
import Illustration from './Illustration'

const OurHistory = (props) => {
    return (
    <>
        {/* Service Area Start */}
        <section id="history" className="py100">
            <div className="container">
                <SectionHeading title="Our History" heading={"It all begins in 2021 with the foundation"}/>
                <div className="row text-center">
                    <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 col-12">
                        <p>Yabok Technology is a company dedicated to the development of technological projects for people and companies at a Global level, we aim to grow your business and take your idea to the next level, focused on the innovation and growth of each of our clients, ensuring maximum user experience. At Yabok Technology we believe in the progress of society and the human condition with the fusion of intelligence, reason and science. That is why we prove to be your trusted partner on the digital integration path.</p>
                        <p>We believe in the progress of humanity by merging intelligence, technology and science. That is why we prove to be your partner trusted on the digital integration path towards transformation of society.</p>
                    </div>
                    <Illustration style={{
                        margin: 'auto',
                        marginTop: 50,
                        maxWidth: '80%'
                    }}/>
                </div>
            </div>
        </section>
        {/* Service Area End */}
    </>
    )
}

export default OurHistory;
