import React from 'react'
import Banner from '../component/Home/Banner'
import Partner from '../component/Home/Partner/Partner'
import AboutOne from '../component/Home/About/AboutOne'
import OurServices from '../component/Home/OurService/OurServices'
import OurHistory from '../component/Home/OurHistory/OurHistory'
import Project from '../component/Home/Project/Project'

const Home = () => {
    return (
        <>
            <Banner/>
            {/* <Partner/> */}
            <OurServices/>
            <AboutOne/>
            {/*<Project/>*/}
            <OurHistory/>
        </>
    )
}

export default Home
