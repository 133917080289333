 import React from 'react'

//  Import Img
 import check from '../../../assets/img/about/tick.svg'
 import AboutImg from '../../../assets/img/about/startup.svg'

 import { Link } from 'react-router-dom';
 
 const AboutOne=()=> {
  return (
   <>
   {/* About One Area Start */}
     <section id="about" className="py100">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="left-side-title">
                        <h3>About Company</h3>
                        <h2 className="pt10">
                            We provide real time data solutions, analytics and predictions
                        </h2>
                    </div>
                    <div className="about-details pt10">
                        <p>
                            It is important to realize that data on its own don't represent anything important but organized in a proper way for a specialist. It becomes valuable information for anyone who with the accurate knowledge and understanding can process and manage this powerful resource.
                        </p>
                        <p>
                            For this reason, it is important to consider technological tools to carry out a precise analytical control of direction that our company is taking and the possible paths that might lead us to success as well as to know the possible routes that we must avoid.
                        </p>
                        <ul className="pt20">
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />Several analysis options
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />Professional Consultant Services
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />Advanced Advisory team
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />24/7 Support Centers
                            </li>
                        </ul>
                        {/* <Link to="/#" className="btn btn-theme mt30">How It Work</Link> */}
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    {/* <div className="animation-img animation-img-one"> */}
                        <img alt="" src={AboutImg}  />
                    {/* </div> */}
                </div>
            </div>
        </div>
    </section>
       {/* About One Area End */}
   </>

  )
 }
 
 export default AboutOne
 