export const MenuData = [
    {
        name: "Home",
        href: "banner-one",
        has_children: false,
    },
    {
        name: "Our Services",
        href: "service",
        has_children: false,
    },
    {
        name: "About Us",
        href: "about",
        has_children: false,
    },
    {
        name: "Projects",
        href: "recent-work",
        has_children: false,
    },
    {
        name: "Our History",
        href: "history",
        has_children: false,
    },
]