import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 371.8"
      xmlSpace="preserve"
      width='500px'
      height='372px'
      {...props}
    >
      <style>
        {
          ".prefix__st1{fill:#b1b4c4}.prefix__st3{fill:#757b89}.prefix__st5{fill:#fff}.prefix__st9{fill:#e9eaf2}.prefix__st10{fill:#dadce5}"
        }
      </style>
      <g id="prefix__\u0421\u043B\u043E\u0439_2">
        <g id="prefix__\u0421\u043B\u043E\u0439_1-2">
          <g id="prefix__Mobile">
            <path
              d="M131.8 367.7l-124.7-72c-10.6-6.1-9.2-16.7 3-23.8L311.2 98.1c12.2-7 30.6-7.8 41.2-1.7l124.7 72c10.6 6.1 9.2 16.8-3 23.8L173 366c-12.2 7-30.6 7.8-41.2 1.7z"
              opacity={0.4}
              fill="#d6d8e5"
            />
            <path
              className="prefix__st1"
              d="M147.6 358.2l-124.7-72c-10.6-6.1-9.2-16.8 3-23.8L327 88.6c12.2-7 30.6-7.8 41.2-1.7l124.7 72c10.6 6.1 9.2 16.7-3 23.8L188.8 356.5c-12.2 7.1-30.7 7.8-41.2 1.7z"
            />
            <path
              className="prefix__st1"
              d="M15.8 275.8v-11.5l13.5 5.1zM500 168.8v-11l-10.7 6.9z"
            />
            <path
              d="M147.6 348.5l-124.7-72c-10.6-6.1-9.2-16.7 3-23.8L327 78.9c12.2-7 30.6-7.8 41.2-1.7l124.7 72c10.6 6.1 9.2 16.8-3 23.8L188.8 346.8c-12.2 7.1-30.7 7.8-41.2 1.7z"
              fill="#616e84"
            />
            <ellipse
              transform="rotate(-30 36.798 289.29)"
              className="prefix__st3"
              cx={36.8}
              cy={289.3}
              rx={1.3}
              ry={2.3}
            />
            <ellipse
              transform="rotate(-30 42.794 292.757)"
              className="prefix__st3"
              cx={42.8}
              cy={292.8}
              rx={1.3}
              ry={2.3}
            />
            <ellipse
              transform="rotate(-30 48.805 296.223)"
              className="prefix__st3"
              cx={48.8}
              cy={296.2}
              rx={1.3}
              ry={2.3}
            />
            <ellipse
              transform="rotate(-30 54.8 299.69)"
              className="prefix__st3"
              cx={54.8}
              cy={299.7}
              rx={1.3}
              ry={2.3}
            />
            <ellipse
              transform="rotate(-29.888 106.75 330.734)"
              className="prefix__st3"
              cx={106.8}
              cy={330.7}
              rx={1.3}
              ry={2.3}
            />
            <ellipse
              transform="rotate(-30 112.317 332.902)"
              className="prefix__st3"
              cx={112.3}
              cy={332.9}
              rx={1.3}
              ry={2.3}
            />
            <ellipse
              transform="rotate(-30 118.318 336.368)"
              className="prefix__st3"
              cx={118.3}
              cy={336.4}
              rx={1.3}
              ry={2.3}
            />
            <ellipse
              transform="rotate(-30 124.314 339.834)"
              className="prefix__st3"
              cx={124.3}
              cy={339.8}
              rx={1.3}
              ry={2.3}
            />
            <path
              className="prefix__st3"
              d="M88.8 321.8l-16.5-9.5c-1.2-.8-1.9-2.2-1.9-3.6s.8-2 1.9-1.4l16.5 9.5c1.2.8 1.9 2.2 1.9 3.6s-.8 2-1.9 1.4zM462.3 196.1L447 205c-1 .6-1.8 0-1.8-1.3s.7-2.6 1.8-3.4l15.3-8.9c1-.6 1.8 0 1.8 1.3-.1 1.4-.7 2.7-1.8 3.4zM438.8 209.7l-15.3 8.9c-1 .6-1.8 0-1.8-1.3s.7-2.6 1.8-3.4l15.3-8.9c1-.6 1.8 0 1.8 1.3-.1 1.4-.7 2.6-1.8 3.4z"
            />
            <path
              d="M147.6 346.8l-124.7-72c-10.6-6.1-9.2-16.8 3-23.8L327 77.1c12.2-7 30.6-7.8 41.2-1.7l124.7 72c10.6 6.1 9.2 16.7-3 23.8L188.8 345c-12.2 7.1-30.7 7.9-41.2 1.8z"
              fill="#20232d"
            />
            <path
              className="prefix__st5"
              d="M479.5 150.3l-48.7-28.1-.1.1c-5.8 3.3-15.1 3.3-20.8 0s-5.8-8.7 0-12l.1-.1-48.7-28.1c-7.4-4.3-19.4-4.3-26.8 0L41.3 251.4c-7.4 4.3-7.4 11.2 0 15.4L159.4 335c7.4 4.3 19.4 4.3 26.8 0l293.3-169.3c7.4-4.2 7.4-11.2 0-15.4z"
            />
            <linearGradient
              id="prefix__SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1={260.381}
              y1={35.744}
              x2={260.381}
              y2={295.145}
              gradientTransform="matrix(1 0 0 -1 0 374)"
            >
              <stop offset={0.21} stopColor="#fff" stopOpacity={0} />
              <stop offset={1} stopColor="#fff" stopOpacity={0.2} />
            </linearGradient>
            <path
              d="M479.5 165.7L186.2 335.1c-6.7 3.9-17.1 4.2-24.5 1.1-.8-.3-1.5-.7-2.2-1.1L41.3 266.8c-1.6-.9-3.1-2.1-4.2-3.6-2.8-4-1.4-8.6 4.2-11.9L334.6 82.1c7.4-4.3 19.4-4.3 26.8 0l48.7 28.1-.1.1c-5.8 3.3-5.8 8.7 0 12s15.1 3.3 20.8 0l.1-.1 48.7 28.1c7.3 4.3 7.3 11.2-.1 15.4z"
              opacity={0.5}
              fill="url(#prefix__SVGID_1_)"
            />
            <ellipse cx={417.1} cy={118.1} rx={5.6} ry={3.2} fill="#2b303f" />
            <path
              d="M415.4 119c-.5-.2-.8-.7-.7-1.2.1-.3.4-.6.7-.7 1-.5 2.3-.5 3.3 0 .5.2.8.7.7 1.2-.1.3-.4.6-.7.7-1 .5-2.2.5-3.3 0z"
              opacity={0.1}
              fill="#fff"
            />
          </g>
          <path
            className="prefix__st9"
            d="M355.4 212.2L251.1 152c-6.1-3.5-5.3-9.6 1.7-13.6L323 97.8c7-4 17.6-4.5 23.7-1L451 157.1c6.1 3.5 5.3 9.6-1.7 13.7l-70.2 40.5c-7 4-17.6 4.4-23.7.9z"
          />
          <path
            className="prefix__st10"
            d="M358.8 142.3l-37.6 5.8 6-13-58.8 9.1 16.4 9.5 26.7 15.4L339 185z"
          />
          <ellipse
            className="prefix__st10"
            cx={407}
            cy={160.9}
            rx={18.6}
            ry={10.8}
          />
          <path
            className="prefix__st9"
            d="M248.9 273.7l-104.3-60.2c-6.1-3.5-5.3-9.6 1.7-13.7l70.2-40.5c7-4 17.6-4.5 23.7-1l104.3 60.2c6.1 3.5 5.3 9.6-1.7 13.7l-70.2 40.5c-7 4.1-17.6 4.5-23.7 1zM236.3 293.7l-70.2 40.5c-1.4.8-2.9 1.5-4.4 1.9-.8-.3-1.5-.7-2.2-1.1L41.3 266.8c-1.6-.9-3.1-2.1-4.2-3.6.8-.7 1.7-1.4 2.7-1.9l70.2-40.5c7-4 17.6-4.5 23.7-1L238 280.1c6 3.5 5.3 9.6-1.7 13.6z"
          />
          <path
            className="prefix__st10"
            d="M145.8 265.3l-37.6 5.8 6-13-58.8 9.1 16.4 9.5 26.7 15.4L126 308z"
          />
          <ellipse
            className="prefix__st10"
            cx={194}
            cy={283.9}
            rx={18.6}
            ry={10.8}
          />
          <path
            className="prefix__st9"
            d="M437.1 141.4l-2.1-1.3 3.5-2 2.2 1.2zM440 143l-2.2-1.2 5-2.9 2.1 1.2zM442.8 144.6l-2.1-1.2 7-4.1 2.1 1.2zM445.6 146.2l-2.1-1.2 9.9-5.7 2.1 1.2zM454.8 151.5l-3.3-1.9 6.6-3.8 3.3 1.9zM459.2 154.1l-3.3-1.9 6.6-3.8 3.3 1.9zM463.6 156.6l-3.3-1.9 6.6-3.8 3.2 1.9z"
          />
          <ellipse cx={243.8} cy={211.8} rx={30.8} ry={17.8} opacity={0.05} />
        </g>
      </g>
      <g id="prefix__Message" className="animation-img animation-img-one">
        <path
          d="M304.3 71.1L201.1 11.5c-2.8-1.6-5.3-1.9-7.3-1-.3.1-.5.3-.8.4l-10.8 6 7.8 11v77.9c0 7 4.9 15.5 11 19l40.4 23.3 4.9 12.7-5.2 22.4 11.4-6.3 11.2-15.9 35 20.2 1.2 10.5 11.3-6.3c2.4-1 3.9-3.6 3.9-7.4V90.1c.2-7-4.7-15.5-10.8-19z"
          fill="#03b0d8"
        />
        <path
          d="M292.9 77.4L189.7 17.8c-6.1-3.5-11-.7-11 6.3v88c0 7 4.9 15.5 11 19l40.4 23.3 11.2 28.8 11.2-15.9 40.4 23.3c6.1 3.5 11 .7 11-6.3v-88c0-6.9-4.9-15.4-11-18.9z"
          fill="#00c8fa"
        />
        <path
          className="prefix__st5"
          d="M244.7 105.6l4.7.9 17.3 15.2-1.9-7.6-11.5-9.8-.1-.2 7.7-5.9-1.9-8.1-11.3 9.5-4.7-1v-5.7l9.6-11.6-5.9-3.5-6.2 8.1-.2-.2-6.2-15.1-6.3-3.7 9.7 22.8v5.8l-4.7-4.5-11.3-22.4-1.8 5.4 7.6 14.8v.2l-11.5-3.5-2 5.8 17.3 4.7 4.7 4.5-2.9 2.9-16.6-2.2 4.8 6.8 10.9 1.1.2.2-.9 13.1 5.1 7.2.9-19.9 3-3 2.9 6.3 1 21 4.8-1.2-.9-14.1h.1l11 11.5 5.1-1.3-16.7-17z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent